import React from "react";
import badgesImg from "../../svg/assets/badges.jpg";
import checkatradeImg from "../../svg/assets/checkatrade.jpg";

const Footer = () => (
  <footer className="py-16 bg-white text-secondary-darker">
    <div className="container mx-auto px-8 flex flex-col text-center">
      <div className="flex-1">
        <h3 className="mb-5 text-xl">Find Us</h3>
        <p className="mb-4">
          <strong>Miller &amp; Krish</strong>
          <br />
        </p>
        <p>Tel: 07834 531115 / 07725 944007</p>
      </div>
      <div className="flex-1 flex-col px-3 pt-4">
        <img src={badgesImg} className="inline h-16" alt="NICEIC" />
      </div>
      <div className="flex-1 flex-col px-3 pt-4">
        <a
          href="https://www.checkatrade.com/trades/millerandkrishelectricalcontr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={checkatradeImg} className="inline h-16" alt="Checkatrade" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
