import React from "react";
import PhoneIcon from "../../svg/PhoneIcon";
import Button from "../Button";

const Header = () => (
  <header className="fixed border bg-white top-0 font-primary text-secondary-darker w-full">
    <div className="container flex flex-row justify-between items-center mx-auto py-3 md:py-4 lg:py-5 px-5 md:px-8 align-middle">
      <div className="flex items-center text-xl md:text-2xl font-semibold">
        Miller &amp; Krish Electrical
      </div>
      <div className="flex sm:mt-0">
        <a href="tel:07834531115">
          <Button size="sm" format="solid" className="flex flex-row">
            <span className="mr-2 align-middle">
              <PhoneIcon />
            </span>
            <div className="invisible hidden sm:visible sm:block">
              07834 531 115
            </div>
            <div className="visible block sm:invisible sm:hidden">CALL</div>
          </Button>
        </a>
      </div>
    </div>
  </header>
);

export default Header;
