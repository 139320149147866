import React from "react";

const sizes = {
  sm: `py-1 px-3`,
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`,
};

const formats = {
  outline: `bg-transparent hover:bg-third hover:text-primary-darker text-third hover:border-transparent`,
  solid: `bg-third hover:text-primary`
};

const Button = ({ children, className = "", size, format, type = "button" }) => {
  return (
    <button
      type={`${type}`}
      className={`
        ${sizes[size] || sizes.default}
        ${formats[format] || formats.outline}
        ${className}
        border 
        border-third 
        rounded
        font-semibold
    `}
    >
      {children}
    </button>
  );
};

export default Button;
